import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

export enum ResponseType {
    JSON,
    Text,
    Blob,
    Response
}

type ContextType = {
    refreshTimers: () => void;
    crabFetch: (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void) => Promise<any>;
};

type TokenRefreshProviderProps = { children: React.ReactNode };

export const TokenRefreshContext = React.createContext<ContextType>({
    refreshTimers: () => {
        throw Error('Cannot use a context without a provider');
    },
    crabFetch: () => {
        throw Error('Cannot use a context without a provider');
    }
});

export default function TokenRefreshProvider({ children }: TokenRefreshProviderProps) {
    const navigate = useNavigate();
    const [openWarning, setOpenWarning] = React.useState(false);
    const warningTimerRef = React.useRef<number | null>(null);
    const logoutTimerRef = React.useRef<number | null>(null);
    let warnTime = 1800000;
    let logoutTime = 2700000;

    React.useEffect(() => {
        setUpTimers();

        return () => {
            if (warningTimerRef.current !== null) {
                window.clearTimeout(warningTimerRef.current);
            }
            if (logoutTimerRef.current !== null) {
                window.clearTimeout(logoutTimerRef.current);
            }
        };
    }, []);

    React.useEffect(() => {
        console.log("");
    }, [warningTimerRef]);

    const setUpTimers = async () => {
        const isAuth = await authService.isAuthenticated();

        if (isAuth) {
            warningTimerRef.current = window.setTimeout(
                () => {
                    setOpenWarning(true);
                }, warnTime
            );

            logoutTimerRef.current = window.setTimeout(
                () => {
                    navigate(`${ApplicationPaths.LogOut}`, {
                        state: {
                            local: true
                        }
                    });
                }, logoutTime
            );
        }
    }

    const refreshTimers = async () => {
        setOpenWarning(false);

        await authService.refreshAccessToken();
        window.clearTimeout(warningTimerRef.current!);
        window.clearTimeout(logoutTimerRef.current!);

        warningTimerRef.current = window.setTimeout(
            () => {
                setOpenWarning(true);
            }, warnTime
        );

        logoutTimerRef.current = window.setTimeout(
            () => {
                setOpenWarning(false);
                navigate(`${ApplicationPaths.LogOut}`, {
                    state: {
                        local: true
                    }
                });
            }, logoutTime
        );
    }

    const preventClose = (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'loading') return;
    }

    const crabFetch = async (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void) => {
        let accessDenied = false;
        fetch(url, options)
            .then(response => {
                accessDenied = response.url.includes("AccessDenied");
                if (type === ResponseType.JSON) return response.json();
                if (type === ResponseType.Text) return response.text();
                if (type === ResponseType.Blob) return response.blob();
                if (type === ResponseType.Response) return response;
            })
            .then(data => {
                if (responseFunc) responseFunc(data);
                refreshTimers();
            })
            .catch((error: Error) => {
                if (catchFunc) catchFunc(error);
                if (!accessDenied) Bugsnag.notify(error);
            });
    }

    return (
        <TokenRefreshContext.Provider value={{ refreshTimers: refreshTimers, crabFetch: crabFetch }}>
            <Dialog
                open={openWarning}
                onClose={preventClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Inactivity Warning</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">You have been inactive for 30 minutes, please press continue to show you are active or you will be logged out in 15 minutes.</Typography>
                    <DialogActions>
                        <Button variant="contained" onClick={refreshTimers}>Continue</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {children}
        </TokenRefreshContext.Provider>
    );
}