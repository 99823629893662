import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ProfileDetails from './ProfileDetails';
import authService from '../api-authorization/AuthorizeService';
import ProfileAdminActions from './Admin/ProfileAdminActions';

interface IProps {
    userId: string;
}

export default function ProfileContainer() {
    const location = useLocation();
    const { userId } = location.state as IProps || {
        userId: ""
    }
    const id = userId;
    const [role, setRole] = React.useState('');

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        setRole(user.role);
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                        <ProfileDetails userId={id} />
                    </Grid>
                    {role === 'Admin' &&
                        <Grid item xs={12}>
                            <ProfileAdminActions userId={userId} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}