import * as React from 'react';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import ImageListItem from '@mui/material/ImageListItem';
interface IProps {
    resource: ResourceViewmodel;
    openView: (index: number) => void;
    index: number;
}
export default function GalleryItem(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { resource, openView, index } = props;
    const [fileSrc, setFileSrc] = React.useState('');

    React.useEffect(() => {
        if (resource.type !== "Embed")
            getFileSrc();
        else
            setFileSrc(resource.content);
    }, [resource]);

    const getFileSrc = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Resource/ViewResourceLink?id=${resource.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            ((response) => {
                setFileSrc(response);
            }),
            (error => {
            })
        );
    }

    return (
        <ImageListItem>
            <img src={fileSrc} style={{ objectFit: 'cover' }} alt={resource.name} loading="lazy" onClick={() => openView(index)} />
        </ImageListItem>
    );
}