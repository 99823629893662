import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StatusViewmodel from './Viewmodels/StatusViewmodel';
import Button from '@mui/material/Button';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';

interface IProps {
    row: StatusViewmodel;
    refresh: () => void;
}

export default function StatusesTableRow(props: IProps) {
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.archived ? 'UnArchiveStatus' : 'ArchiveStatus';

        crabFetch(`Status/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} status`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <TableRow>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.archived ? 'True' : 'False'}</TableCell>
            <TableCell align="right"><Button aria-label={row.archived ? "Unarchive" : "Archive" + " Status"} onClick={toggleArchive} variant="contained" disabled={loading}>{row.archived ? "Unarchive" : "Archive"}</Button></TableCell>
        </TableRow>
    );
}