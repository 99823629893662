import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import EditProjectViewmodel from '../Viewmodels/EditProjectViewmodel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertContext } from '../../Contexts/AlertContext';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import ProjectViewmodel from '../Viewmodels/ProjectViewmodel';



interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    project: ProjectViewmodel;
}

export default function EditProject(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, project } = props;
    const [loading, setLoading] = React.useState(false);
    const [instructingParties, setInstructingParties] = React.useState<DropdownViewModel[]>([]);
    const [consultants, setConsultants] = React.useState<DropdownViewModel[]>([]);

    const formContext = useForm<EditProjectViewmodel>({
        defaultValues: new EditProjectViewmodel()
    });
    const { reset } = formContext;

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        reset({
            id: project.id,
            name: project.name,
            instructingParty: project.instructingParty.id as number,
            managingConsultant: project.managingConsultant.id as string
        });
    }, [project]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`User/GetManagingConsultantsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setConsultants(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`InstructingParty/GetInstructingPartiesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setInstructingParties(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();

        crabFetch('Project/UpdateProject', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated project");
                    onClose(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const closeDialog = async () => {
        onClose(false);
        reset({
            id: project.id,
            name: project.name,
            instructingParty: project.instructingParty.id as number,
            managingConsultant: project.managingConsultant.id as string
        });
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Edit Project"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="instructingParty" shrink>Instructing Party</InputLabel>
                            <SelectElement
                                name="instructingParty"
                                required
                                fullWidth
                                size="small"
                                options={instructingParties}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="managingConsultant" shrink>Managing Consultant</InputLabel>
                            <SelectElement
                                name="managingConsultant"
                                required
                                fullWidth
                                size="small"
                                options={consultants}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}