import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PermissionCheck from '../../Utilities/PermissionCheck';
import ProjectViewmodel from '../Viewmodels/ProjectViewmodel';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import authService from '../../api-authorization/AuthorizeService';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import SectionContainer from '../../Sections/SectionContainer';
import ProjectActivityTable from './ProjectActivityTable';
import ProjectViewsTable from './ProjectViewsTable';
import ToggleSections from './ToggleSections';
import UpdateStatus from './UpdateStatus';
import { AlertContext } from '../../Contexts/AlertContext';
import EditProject from './EditProject';
import WarningDialog from '../../Utilities/WarningDialog';
import ProjectUserList from './ProjectUserList';
import Alert from '@mui/material/Alert/Alert';

interface IProps {
    projectId: string;
}

export default function ProjectContainer() {
    const location = useLocation();
    const { show } = React.useContext(AlertContext);
    const { projectId } = location.state as IProps || {
        projectId: ""
    }
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [project, setProject] = React.useState(new ProjectViewmodel());
    const [loading, setLoading] = React.useState(true);
    const [openSectionDialog, setOpenSectionDialog] = React.useState(false);
    const [openStatusDialog, setOpenStatusDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openArchiveWarning, setOpenArchiveWarning] = React.useState(false);
    const [role, setRole] = React.useState('');

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        if (user && user.sub) {
            setRole(user ? user.role : '');
        }

        await crabFetch(`Project/GetProject?id=${projectId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setProject(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const edit = (refresh: boolean) => {
        setOpenEditDialog(false);

        if (refresh)
            getData();
    }

    const updateStatus = (refresh: boolean) => {
        setOpenStatusDialog(false);

        if (refresh)
            getData();
    }

    const closeSectionDialog = (refresh: boolean) => {
        setOpenSectionDialog(false);

        if (refresh)
            getData();
    }

    const updatePublish = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Project/UpdatePublishState?id=${projectId}&publish=${!project.published}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    const message = "Successfully " + (project.published ? "unpublished project." : "published project.");
                    show('success', message);
                    getData();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const archive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Project/Archive?id=${projectId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully archived project.");
                    getData();
                    setOpenArchiveWarning(false);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <React.Fragment>
            {loading ?
                <CircularProgress size={15} />
                :
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={(role === 'Admin' || role === 'Manager') ? 6 : 12} md={(role === 'Admin' || role === 'Manager') ? 8 : 12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Grid container item justifyContent="space-between" spacing={2}>
                                        <Grid container item justifyContent="space-between" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h1">{project.name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h2">{project.archived ? "Archived" : project.published ? "Published" : ""}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={2}>
                                            <Grid item xs={3}>
                                                <InputLabel htmlFor="consultant" shrink>Managing Consultant</InputLabel>
                                                <TextField name="consultant" fullWidth value={project.managingConsultant.label} disabled />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <InputLabel htmlFor="status" shrink>Project Status</InputLabel>
                                                <TextField name="status" fullWidth value={project.projectStatus.label} disabled />
                                            </Grid>
                                            { project.dueDate &&
                                                <Grid item xs={3}>
                                                    <InputLabel htmlFor="date" shrink>Due Date</InputLabel>
                                                    <TextField name="date" fullWidth value={new Date(project.dueDate).toLocaleDateString('en-gb')} disabled />
                                                </Grid>
                                            }
                                            <Grid item xs={3}>
                                                <InputLabel htmlFor="party" shrink>Instructing Party</InputLabel>
                                                <TextField name="party" fullWidth value={project.instructingParty.label} disabled />
                                            </Grid>
                                        </Grid>
                                        {!project.archived &&
                                            <Grid container item spacing={2}>
                                                <PermissionCheck userType={["Admin", "Manager"]}>
                                                    <Grid item xs>
                                                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenEditDialog(true)}>Edit</Button>
                                                    </Grid>
                                                </PermissionCheck>
                                                <PermissionCheck userType={["Admin", "Manager"]} >
                                                    <Grid item xs>
                                                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenStatusDialog(true)}>Update Status</Button>
                                                    </Grid>
                                                </PermissionCheck>
                                                <PermissionCheck userType={["Admin", "Manager"]} managingConsulantName={project.managingConsultant.label}>
                                                    <Grid item xs>
                                                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenSectionDialog(true)}>Select Sections</Button>
                                                    </Grid>
                                                </PermissionCheck>
                                                <PermissionCheck userType={["Admin", "Manager"]} managingConsulantName={project.managingConsultant.label}>
                                                    <Grid item xs>
                                                        <Button variant="contained" fullWidth size="small" onClick={updatePublish}>{project.published ? "Unpublish" : "Publish"}</Button>
                                                    </Grid>
                                                </PermissionCheck>
                                                <PermissionCheck userType={["Admin", "Manager"]} managingConsulantName={project.managingConsultant.label}>
                                                    <Grid item xs>
                                                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenArchiveWarning(true)}>Archive</Button>
                                                    </Grid>
                                                </PermissionCheck>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid justifyContent="space-between" sx={{ pt: 2 }}>
                                        <Alert variant="outlined" severity="info">
                                             This page contains options to download sensitive infomation, please ensure you don't share any downloaded files with unauthorised persons.
                                        </Alert>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {project.sections.filter(f => f.enabled).map((item) => {
                                return <SectionContainer key={'section' + item.id} id={item.id} projectId={project.id} archived={project.archived} managingConsultant={project.managingConsultant.label }  />;
                            })}

                            <PermissionCheck userType={["Admin", "Manager", "Consultant"]}>
                                <Grid item xs={12}>
                                    <ProjectActivityTable projectId={project.id} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProjectViewsTable projectId={project.id} />
                                </Grid>
                            </PermissionCheck>
                        </Grid>
                    </Grid>
                    <PermissionCheck userType={["Admin", "Manager", "Consultant"]}>
                        <Grid item xs>
                            <Paper>
                                <ProjectUserList projectId={project.id} projectName={project.name} />
                            </Paper>
                        </Grid>
                    </PermissionCheck>
                    <ToggleSections open={openSectionDialog} onClose={closeSectionDialog} projectId={project.id} sections={project.sections} />
                    <UpdateStatus open={openStatusDialog} onClose={updateStatus} projectId={project.id} currentStatus={project.projectStatus} statusDueDate={project.dueDate } />
                    <EditProject open={openEditDialog} onClose={edit} project={project} />
                    <WarningDialog open={openArchiveWarning} close={() => setOpenArchiveWarning(false)} buttonText='Archive' text='This will archive the project and delete all of the project resources. Are you sure you wish to proceed?' okClick={archive} />
                </Grid>
            }
        </React.Fragment>
    );
}