import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ProjectListViewmodel from './Viewmodels/ProjectListViewmodel';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';

interface IProps {
    row: ProjectListViewmodel;
}

export default function ProjectTableRow(props: IProps) {
    const navigate = useNavigate();
    const { row } = props;

    const viewProject = () => {
        navigate(
            '/Projects/Project',
            {
                state: {
                    projectId: row.id
                }
            }
        );
    }

    return (
        <TableRow onClick={viewProject} hover>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.instructingParty}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.publishDate ? new Date(row.publishDate).toLocaleDateString('en-gb') : ''}</TableCell>
            <TableCell>{new Date(row.created).toLocaleDateString('en-gb')}</TableCell>
            <TableCell align="right"><Button aria-label={"View Project"} onClick={viewProject} variant="contained">{row.status === "Archived" ? "View" : "View/Edit"}</Button></TableCell>
        </TableRow>
    );
}