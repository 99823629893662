import * as React from 'react';
import Layout from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import AlertProvider from './components/Contexts/AlertContext';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import Login from './components/api-authorization/Login';
import Logout from './components/api-authorization/Logout';
import { ThemeProvider, responsiveFontSizes, createTheme } from '@mui/material/styles';
import TokenRefreshProvider from './components/Contexts/TokenRefreshContext';
import TermsCondsProvider from './components/Contexts/TermsCondsContext';
import ProfileContainer from './components/Profile/ProfileContainer';
import RegisterConfirmation from './components/Profile/RegisterConfirmation';
import ProjectTable from './components/Projects/ProjectTable';
import ProjectContainer from './components/Projects/Project/ProjectContainer';
import ProjectActivityTable from './components/Projects/Project/ProjectActivityTable';
import StatusesTable from './components/Statuses/StatusesTable';
import InstructingPartyTable from './components/InstructingParties/InstructingPartyTable';
import UserDashboard from './components/Users/UserDashboard';

let theme = createTheme({
    shape: {
        borderRadius: 10
    },
    typography: {
        fontFamily: 'Source Sans 3',
        h1: {
            fontWeight: 600,
            fontSize: 20
        },
        h2: {
            fontWeight: 500,
            fontSize: 18
        },
        h3: {
            fontWeight: 400,
            fontSize: 16
        },
        h4: {
            fontWeight: 400,
            fontSize: 14
        },
        h5: {
            fontWeight: 400,
            fontSize: 14,
            color: '#033878'
        },
        h6: {
            fontWeight: 300,
            fontSize: 14
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: 14
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 14
        },
        body1: {
            fontWeight: 400,
            fontSize: 14
        },
        body2: {
            fontWeight: 300,
            fontSize: 14
        },
        caption: {
            fontWeight: 300,
            fontSize: 14,
            color: '#B5ADC4'
        },
        overline: {
            fontWeight: 300,
            fontSize: 14
        },
        button: {
            fontWeight: 400,
            fontSize: 14,
            textTransform: 'none'
        }
    },
    palette: {
        background: {
            default: '#F2F4F8',
            paper: '#fff'
        },
        text: {
            primary: '#1A263E',
            secondary: '#B5ADC4'
        },
        primary: {
            main: '#4F297B',
            contrastText: 'white'
        },
        secondary: {
            main: '#9A84BC',
            contrastText: 'white'
        },
        error: {
            main: '#F65647'
        }
    },
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    border: '1px solid #F5F6F8',
                    padding: '16px',
                    boxShadow: '6px 4px 20px rgba(66, 111, 205, 0.15)'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    boxShadow: 'inset 6px 4px 20px #426FCD26'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    //borderColor: '#707683 !important',
                    borderWidth: 0,
                    '&:focus': {
                        borderColor: '#B5ADC4'
                    },
                    "&.Mui-focused": {
                        border: '2px solid #B5ADC4',
                        "&.MuiOutlinedInput-notchedOutline": {
                            border: '2px solid #B5ADC4'
                        }
                    }
                },
                root: {
                    backgroundColor: '#F2F4F8',
                    borderWidth: 0,
                    '&.Mui-focused fieldset': {
                        borderColor: "#B5ADC4 !important",
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    borderRadius: '10px 10px 0 0'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    //border: '1px solid #707683 !important',
                    //borderTop: 'none !important',
                    //boxShadow: 'none !important',
                    borderRadius: '10px',
                    padding: '0px !important'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        (ownerState.color === 'primary' ? {
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                                boxShadow: 'none'
                            }
                        } : {
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                boxShadow: 'none'
                            }
                        })
                    ),
                    boxShadow: 'none',
                    textTransform: 'none',
                }),
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    '&:nth-of-type(odd) td': {
                        backgroundColor: '#F2F4F8',
                        '&:first-of-type': {
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5
                        },
                        '&:last-child': {
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5
                        },
                    },
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& tr': {
                        backgroundColor: '#fff!important'
                    }
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.h5,
                    "&.Mui-active": {
                        color: '#4F297B !important'
                    }
                }),
                icon: {
                    color: '#4F297B !important'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                body: {
                    border: 'none'
                },
                head: {
                    borderColor: '#B5ADC4'

                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 0
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.h1,
                    borderBottom: "1px solid #B5ADC4"
                })
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingTop: '16px!important',
                    color: theme.palette.text.primary
                })
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid #B5ADC4',
                    margin: '16px -24px 0',
                    padding: '16px 24px 0'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1A263EB2',
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomWidth: 2,
                    borderColor: theme.palette.text.secondary
                })
            }
        }
    }
});

theme = responsiveFontSizes(theme);

function loginAction(name: any) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: any) {
    return (<Logout action={name}></Logout>);
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <AlertProvider>
                    <TokenRefreshProvider>
                        <TermsCondsProvider>
                            <Layout>
                                <Routes>
                                    <Route path='/' element={<AuthorizeRoute component={ProjectTable} />} />
                                    <Route path='/ManageUsers' element={<AuthorizeRoute admin manager component={UserDashboard} />} />
                                    <Route path='/Profile' element={<AuthorizeRoute component={ProfileContainer} />} />
                                    <Route path='/RegisterConfirmation/:userId/:code' element={<RegisterConfirmation />} />

                                    <Route path='/Projects' element={<AuthorizeRoute component={ProjectTable} />} />
                                    <Route path='/Projects/Project' element={<AuthorizeRoute component={ProjectContainer} />} />

                                    <Route path='/UserActivity' element={<AuthorizeRoute admin manager component={ProjectActivityTable} />} />
                                    <Route path='/ManageStatuses' element={<AuthorizeRoute admin component={StatusesTable} />} />
                                    <Route path='/InstructingParties' element={<AuthorizeRoute admin component={InstructingPartyTable} />} />

                                    <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                                        <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                                        <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                                        <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                                        <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                                        <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                                        <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                                        <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                                        <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
                                    </Route>
                                </Routes>
                            </Layout>
                        </TermsCondsProvider>
                    </TokenRefreshProvider>
                </AlertProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
