import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AccessType } from '../Utilities/AccessEnums';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ManageAccess from '../Users/ManageAccess';
import AccessListViewmodel from '../Users/Viewmodels/AccessListViewmodel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ResponsiveTable from '../Utilities/ResponsiveTable';

interface IProps {
    projectId: number;
    resourceIds: number | number[];
    name: string;
}

export default function ResourceUserList(props: IProps) {
    const { projectId, resourceIds, name } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [list, setList] = React.useState<AccessListViewmodel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openAddEdit, setOpenAddEdit] = React.useState(false);
    const [editUser, setEditUser] = React.useState<AccessListViewmodel | null>(null);

    React.useEffect(() => {
        if (!(Array.isArray(resourceIds) && resourceIds.length > 0) && resourceIds > 0) {
            getData();
        }
    }, [resourceIds]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const body = {
            accessType: AccessType.Resource,
            ids: Array.isArray(resourceIds) ? resourceIds : [resourceIds]
        };

        crabFetch(`Access/GetUserAccessList`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(body)
        }, ResponseType.JSON,
            (data) => {
                setLoading(false);
                setList(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const closeAddEdit = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAddEdit(false);
        setEditUser(null);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3">Users with Access</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenAddEdit(true)}>Add User</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {loading &&
                    <CircularProgress size={15} />
                }
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <ResponsiveTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>Access</TableCell>
                                <TableCell>Access Expiry</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.map((item, index) => {
                                return <TableRow key={'resourceUsers' + item.id}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{item.userName}</TableCell>
                                    <TableCell>{item.level}</TableCell>
                                    <TableCell>{item.expiry}</TableCell>
                                    <TableCell align="right"><Button variant="contained" fullWidth size="small" onClick={() => setEditUser(item)}>Edit</Button></TableCell>
                                </TableRow>;
                            })}
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
            </Grid>
            {(openAddEdit || editUser !== null) && <ManageAccess open={openAddEdit || editUser !== null} id={resourceIds} projectId={projectId} userEdit={editUser} label={name} type={AccessType.Resource} onClose={closeAddEdit} />}
        </Grid>
    );
}