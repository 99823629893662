import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel'
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { LazyLoadImage } from "react-lazy-load-image-component";
interface IProps {
    open: boolean;
    onClose: () => void;
    resources: ResourceViewmodel[];
    selectedIndex: number;
    sectionName: string;
    sectionId: number;
}
export default function ViewImageCarousel(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { open, onClose, resources, selectedIndex, sectionName, sectionId } = props;
    const [fileSrcs, setFileSrcs] = React.useState<string[]>([]);
    const [viewingIndex, setViewingIndex] = React.useState(0);

    const getFileUrls = async () => {
        if (resources.length > 0) {
            const token = await authService.getAccessToken();

            crabFetch(`Resource/ViewResourceList?sectionId=${sectionId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }, ResponseType.JSON,
                ((response) => {
                    setFileSrcs(response);
                }),
                ((error: any) => {
                })
            );
        }
    }

    React.useEffect(() => {
        if (selectedIndex > -1) {
            setViewingIndex(selectedIndex);
        }
    }, [selectedIndex]);

    React.useEffect(() => {
        getFileUrls();
    }, [resources]);

    const updateViewingIndex = (number: number) => {
        const newIndex = viewingIndex + number;

        if (newIndex < 0) setViewingIndex(fileSrcs.length - 1);
        else if (newIndex >= fileSrcs.length) setViewingIndex(0)
        else setViewingIndex(newIndex)
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>
                {`Viewing ${sectionName} - ${resources[viewingIndex].name}`}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent  >
                {resources.length > 0 &&
                    <Carousel
                        next={() => { updateViewingIndex(1) }}
                        prev={() => { updateViewingIndex(-1) }}
                        autoPlay={false}
                        swipe={false} //stops expand on open animation
                        index={selectedIndex > -1 ? selectedIndex : 0} >
                        {fileSrcs.length > 0 &&
                            fileSrcs.map((item, index) =>
                                <LazyLoadImage
                                    key={'image' + index}
                                    src={item}
                                    alt={`Image Alt`}
                                    height={window.innerWidth - (window.innerWidth * 0.65)}
                                    width='100%'
                                    style={{ objectFit: 'contain' }}
                                />
                            )
                        }
                    </Carousel>
                }
            </DialogContent>
        </Dialog>
    );
}