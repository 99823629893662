import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SwitchElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm, useFieldArray } from 'react-hook-form';
import SectionTypeViewmodel from '../Viewmodels/SectionTypeViewmodel';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    projectId: number;
    sections: SectionTypeViewmodel[];
}

type FormValues = {
    sections: SectionTypeViewmodel[];
}

export default function ToggleSections(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, projectId, sections } = props;
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm<FormValues>({
        defaultValues: { sections: sections }
    });
    
    const { fields } = useFieldArray({ name: "sections", control: formContext.control });
    const { reset } = formContext;

    React.useEffect(() => {
        reset({ sections: sections });
    }, [sections]);

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const postData = {
            projectId: projectId,
            sections: form.sections
        }

        crabFetch('Project/UpdateProjectSections', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(postData)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated project sections");
                    onClose(true);
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialog = async () => {
        onClose(false)
        reset({ sections: sections })
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Enable / Disable Project Sections"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {fields.map((field, index) => {
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <SwitchElement
                                        label={field.name}
                                        name={`sections.${index}.enabled`}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}