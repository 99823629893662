import * as React from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import ResourceTableRow from './ResourceTableRow';
import TableHead from '@mui/material/TableHead';

interface IProps {
    refreshData: () => void;
    data: ResourceViewmodel[];
    openView: (index: number) => void;
    openEdit: (resource: ResourceViewmodel) => void;
    archived: boolean;
    sectionName: string;
}

export default function ResourceTable(props: IProps) {
    const { refreshData, data, openView, openEdit, archived, sectionName } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [data, page, rowsPerPage]
    );

    return (
        <React.Fragment>
            <TableContainer>
                <ResponsiveTable
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="resource table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            {sectionName === "Invoices" &&
                                <TableCell>Paid status</TableCell>
                            }
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data) && visibleRows.map((row: ResourceViewmodel, index) => {
                            return (<ResourceTableRow key={row.id} row={row} refreshData={refreshData} openView={openView} index={index} archived={archived} openEdit={openEdit} sectionName={sectionName} />);
                        })
                        }
                        {data.length <= 0 &&
                            <TableRow
                                sx={{
                                    height: 53 * rowsPerPage,
                                }}
                            >
                                <TableCell colSpan={2} align="center">No Results Found</TableCell>
                            </TableRow>
                        }
                        {data.length > 0 && emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={2} />
                            </TableRow>
                        )}
                    </TableBody>
                </ResponsiveTable>
            </TableContainer>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}