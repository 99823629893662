export default class UserViewmodel {
    public id: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public role: string = '';
    public archived: boolean = false;
    public date: Date = new Date();
    public confirmed: boolean = false;
    public status: string = '';
}