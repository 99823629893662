import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ActivityViewmodel from '../Viewmodels/ActivityViewmodel';

interface IProps {
    row: ActivityViewmodel;
    projectId?: number;
}

export default function ProjectActivityTableRow(props: IProps) {
    const { row, projectId } = props;

    const date = new Date(row.date);

    return (
        <TableRow>
            <TableCell>{row.description}</TableCell>
            {projectId === undefined && <TableCell>{row.project}</TableCell>}
            <TableCell>{row.actionedBy}</TableCell>
            <TableCell align="right">{date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</TableCell>
        </TableRow>
    );
}