import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AccessType } from '../../Utilities/AccessEnums';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ManageAccess from '../../Users/ManageAccess';
import AccessListViewmodel from '../../Users/Viewmodels/AccessListViewmodel';
import PermissionCheck from '../../Utilities/PermissionCheck';

interface IProps {
    projectId: number;
    projectName: string;
}

export default function ProjectUserList(props: IProps) {
    const { projectId, projectName } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [list, setList] = React.useState<AccessListViewmodel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openAddEdit, setOpenAddEdit] = React.useState(false);
    const [editUser, setEditUser] = React.useState<AccessListViewmodel | null>(null);

    React.useEffect(() => {
        if (projectId > 0)
            getData();
    }, [projectId]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const body = {
            accessType: AccessType.Project,
            ids: [projectId]
        };

        crabFetch(`Access/GetUserAccessList`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(body)
        }, ResponseType.JSON,
            (data) => {
                setLoading(false);
                setList(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const onClose = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAddEdit(false);
        setEditUser(null);
    }

    return (
        <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
                <Typography variant="h1">Project Users</Typography>
            </Grid>
            <PermissionCheck userType={["Admin", "Manager"]}>
                <Grid item>
                    <Button variant="contained" fullWidth size="small" onClick={() => setOpenAddEdit(true)}>Add User</Button>
                </Grid>
            </PermissionCheck>
            <Grid item xs={12}>
                {loading &&
                    <CircularProgress size={15} />
                }
                {list.map(item => {
                    return <Grid container spacing={2} justifyContent="space-between" key={'users' + item.id}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h2">{item.userName}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{item.level}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {item.expiry}
                                </Grid>
                            </Grid>
                        </Grid>
                        <PermissionCheck userType={["Admin", "Manager"]}>
                            <Grid item>
                                <Button variant="contained" fullWidth size="small" onClick={() => setEditUser(item)}>Edit</Button>
                            </Grid>
                        </PermissionCheck>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                    </Grid>;
                })}
            </Grid>
            {(openAddEdit || editUser !== null) && <ManageAccess open={openAddEdit || editUser !== null} id={projectId} projectId={projectId} userEdit={editUser} label={projectName} type={AccessType.Project} onClose={onClose} />}
        </Grid>
    );
}