import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import Button from '@mui/material/Button';
import WarningDialog from '../Utilities/WarningDialog';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import PermissionCheck from '../Utilities/PermissionCheck';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import Popper from '@mui/material/Popper/Popper';
import Paper from '@mui/material/Paper/Paper';
import Grow from '@mui/material/Grow/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList/MenuList';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid/Grid';

interface IProps {
    row: ResourceViewmodel;
    refreshData: () => void;
    openView: (index: number) => void;
    openEdit: (resource: ResourceViewmodel) => void;
    index: number;
    archived: boolean;
    sectionName: string;
}

export default function ResourceTableRow(props: IProps) {
    const { row, refreshData, openView, index, archived, openEdit, sectionName } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [openWarning, setOpenWarning] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [fileLink, setFileLink] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (row.type === 'File') {
            getFileLink();
        }
    }, [row]);

    const deleteResource = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Resource/Delete?id=' + row.id, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully deleted resource");
                    setOpenWarning(false);
                    refreshData();
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const getFileLink = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Resource/ViewResourceLink?id=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            ((response) => {
                setFileLink(response);
            }),
            (error => {
            })
        );
    }

    const download = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Resource/DownloadResource?id=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Blob,
            ((blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.download = row.name;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            })
        );
    }

    const pay = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Resource/UpdatePaidStatus?id='+row.id, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Invoice succsessfully set to ".concat(row.status));
                    refreshData();
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                {sectionName === "Invoices" && !archived &&
                    <TableCell>{row.status}</TableCell>
                }
                {(sectionName !== "Invoices" && !archived) ?
                    <TableCell align="right">
                        <Grid container spacing={2} justifyContent="flex-end">
                            {!archived &&
                                <Grid item>
                                    {row.type === "File" ?
                                        <Button aria-label={"View"} href={fileLink} target="_blank" variant="contained" color="primary" disabled={loading}>{"View"}</Button>
                                        :
                                        <Button aria-label={"View"} onClick={() => openView(index)} variant="contained" color="primary" disabled={loading}>{"View"}</Button>
                                    }
                                </Grid>
                            }
                            {(row.type === "File" && !archived) &&
                                <Grid item>
                                    <Button aria-label={"Download"} onClick={download} variant="contained" color="primary" disabled={loading}>{"Download"}</Button>
                                </Grid>
                            }
                            {!archived &&
                                <PermissionCheck userType={["Admin", "Manager"]}>
                                    <Grid item>
                                        <Button aria-label={"Edit"} onClick={() => openEdit(row)} variant="contained" color="primary" disabled={loading}>{"Edit"}</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button aria-label={"Delete"} onClick={() => setOpenWarning(true)} variant="contained" color="error" disabled={loading}>{"Delete"}</Button>
                                    </Grid>
                                </PermissionCheck>
                            }
                        </Grid>
                    </TableCell>
                    :
                    <TableCell align="right">
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="User actions dropdown">
                            <Button
                                size="small"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                            >
                                Options<ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                {sectionName === "Invoices" &&
                                                    <MenuItem
                                                        aria-label={"PaidStatus"}
                                                        onClick={pay}
                                                        disabled={loading}
                                                    >
                                                        Update Paid Status
                                                    </MenuItem>
                                                }
                                                <MenuItem
                                                    aria-label={"View"}
                                                    disabled={loading}
                                                    href={fileLink}
                                                    component="a"
                                                    target="_blank"
                                                >
                                                    View
                                                </MenuItem>
                                                {row.type === "File" &&
                                                    <MenuItem
                                                        aria-label={"Download"}
                                                        onClick={download}
                                                        disabled={loading}
                                                    >
                                                        Download
                                                    </MenuItem>
                                                }
                                                <PermissionCheck userType={["Admin", "Manager"]}>
                                                    <MenuItem
                                                        aria-label={"Edit"}
                                                        onClick={() => openEdit(row)}
                                                        disabled={loading}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        aria-label={"Delete"}
                                                        onClick={() => setOpenWarning(true)}
                                                        disabled={loading}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </PermissionCheck>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>

                    </TableCell>
                    }
            </TableRow>
            <WarningDialog open={openWarning} close={() => setOpenWarning(false)} okClick={deleteResource} text="This will permanently delete this resource." buttonText="Delete" />
        </React.Fragment>
    );
}