import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../../Utilities/SortableTableHead';
import ProjectViewsViewmodel from '../Viewmodels/ProjectViewsViewmodel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import ProjectViewsTableRow from './ProjectViewsTableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    projectId: number;
}

const headCells: HeadCell<ProjectViewsViewmodel>[] = [
    { id: 'user', property: 'User', align: "left", disablePadding: false, label: 'User' },
    { id: 'visits', property: 'Visits', align: "right", disablePadding: false, label: 'Number of Visits' },
    { id: 'lastVisit', property: 'LastVisit', align: "right", disablePadding: false, label: 'Date of Last Visit' },
];

export default function ProjectViewsTable(props: IProps) {
    const { projectId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof ProjectViewsViewmodel>('visits');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<ProjectViewsViewmodel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Project/GetProjectViews?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=&id=${projectId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ProjectViewsViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const collapseSection = () => {
        setCollapsed(!collapsed);
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton onClick={collapseSection} size="small">{collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }} alignItems="center">
                            <Typography variant="h1">Project Views by User</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={!collapsed}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="project views"
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {(!loading && results) && results.map((row: ProjectViewsViewmodel) => {
                                        return (<ProjectViewsTableRow key={row.id} row={row} />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {count > 0 && emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} />
                                        </TableRow>
                                    )}
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </Paper>
    );
}