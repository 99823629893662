import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ProjectViewsViewmodel from '../Viewmodels/ProjectViewsViewmodel';

interface IProps {
    row: ProjectViewsViewmodel;
    projectId?: number;
}

export default function ProjectViewsTableRow(props: IProps) {
    const { row } = props;

    return (
        <TableRow>
            <TableCell>{row.user}</TableCell>
            <TableCell align="right">{row.visits}</TableCell>
            <TableCell align="right">{row.lastVisit}</TableCell>
        </TableRow>
    );
}