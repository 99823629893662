import DropdownViewModel from "../../Utilities/ViewModels/DropdownViewModel";
import SectionTypeViewmodel from "./SectionTypeViewmodel";

export default class ProjectViewmodel {
    public id: number = 0;
    public name: string = '';
    public published: boolean = false;
    public archived: boolean = false;
    public canEdit: boolean = false;
    public instructingParty: DropdownViewModel = new DropdownViewModel();
    public managingConsultant: DropdownViewModel = new DropdownViewModel();
    public projectStatus: DropdownViewModel = new DropdownViewModel();
    public sections: SectionTypeViewmodel[] = [];
    public dueDate: Date = new Date();
}