import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import EditInstructingPartyViewmodel from '../ViewModels/EditInstructingPartyViewmodel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';


interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    instructingPartyId: number | null;
}

export default function EditInstructingParty(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, instructingPartyId } = props;
    const [loading, setLoading] = React.useState(false);
    const [instructingParty, setInstructingParty] = React.useState<EditInstructingPartyViewmodel>(new EditInstructingPartyViewmodel());

    React.useEffect(() => {
        if (instructingPartyId !== null) { getData() }
    }, [instructingPartyId]);

    const formContext = useForm<EditInstructingPartyViewmodel>({
        defaultValues: new EditInstructingPartyViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            id: instructingParty.id,
            companyName: instructingParty.companyName,
            contactName: instructingParty.contactName,
            email: instructingParty.email,
            jobTitle: instructingParty.jobTitle,
            office: instructingParty.office
        });
    }, [instructingParty]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`InstructingParty/GetInstructingParty?id=${instructingPartyId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: EditInstructingPartyViewmodel) => {
                setLoading(false);
                setInstructingParty(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();

        crabFetch('InstructingParty/UpdateInstructingParty', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated Instructing Party name");
                    onClose(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }
    
    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Edit Instructing Party"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="companyName" shrink>Company Name</InputLabel>
                            <TextFieldElement name="companyName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="contactName" shrink>Contact Name</InputLabel>
                            <TextFieldElement name="contactName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="jobTitle" shrink>Job Title</InputLabel>
                            <TextFieldElement name="jobTitle" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="email" shrink>Email</InputLabel>
                            <TextFieldElement parseError={() => "Please enter a valid email address"} type={'email'} name="email" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="office" shrink>Office</InputLabel>
                            <TextFieldElement name="office" required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}