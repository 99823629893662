import * as React from 'react';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AccessType } from '../Utilities/AccessEnums';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ManageAccess from '../Users/ManageAccess';
import AccessListViewmodel from '../Users/Viewmodels/AccessListViewmodel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ResponsiveTable from '../Utilities/ResponsiveTable';

interface IProps {
    projectId: number;
    sectionId: number;
    name: string;
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function SectionUserList(props: IProps) {
    const { projectId, sectionId, name, open, onClose } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [list, setList] = React.useState<AccessListViewmodel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openAddEdit, setOpenAddEdit] = React.useState(false);
    const [editUser, setEditUser] = React.useState<AccessListViewmodel | null>(null);

    React.useEffect(() => {
        if (sectionId > 0)
            getData();
    }, [sectionId]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const body = {
            accessType: AccessType.Section,
            ids: [sectionId]
        };

        crabFetch(`Access/GetUserAccessList`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(body)
        }, ResponseType.JSON,
            (data) => {
                setLoading(false);
                setList(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const closeAddEdit = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAddEdit(false);
        setEditUser(null);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        {"Manage Access to " + name}
                    </Grid>
                    <Grid item>
                        <Button variant="contained" fullWidth size="small" onClick={() => setOpenAddEdit(true)}>Add User</Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {loading &&
                    <CircularProgress size={15} />
                }
                <TableContainer>
                    <ResponsiveTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>Access</TableCell>
                                <TableCell>Access Expiry</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.map((item, index) => {
                                return <TableRow key={'sectionUsers' + item.id}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{item.userName}</TableCell>
                                    <TableCell>{item.level}</TableCell>
                                    <TableCell>{item.expiry}</TableCell>
                                    <TableCell align="right"><Button variant="contained" fullWidth size="small" onClick={() => setEditUser(item)}>Edit</Button></TableCell>
                                </TableRow>;
                            })}
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
                {(openAddEdit || editUser !== null) && <ManageAccess open={openAddEdit || editUser !== null} id={sectionId} projectId={projectId} userEdit={editUser} label={name} type={AccessType.Section} onClose={closeAddEdit} />}
                <DialogActions>
                    <Button onClick={() => onClose(false)} variant="contained" disabled={loading}>Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    );
}