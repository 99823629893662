import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';

var path = window.location.hostname;
var stage = path.includes('localhost') ? 'Local' : path.includes('dev') ? 'Development' : 'Production';

Bugsnag.start({
    apiKey: 'd38821ffe3b053f84688e28c11f273fc',
    plugins: [new BugsnagPluginReact()],
    releaseStage: stage
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

root.render(
    <React.Fragment>
        {ErrorBoundary !== undefined &&
            <ErrorBoundary>
                <BrowserRouter basename={baseUrl ?? undefined}>
                    <App />
                </BrowserRouter>
            </ErrorBoundary>
        }

        {ErrorBoundary === undefined &&
            <BrowserRouter basename={baseUrl ?? undefined}>
                <App />
            </BrowserRouter>
        }
    </React.Fragment>
);