import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import Typography from '@mui/material/Typography';
import { InputLabel, TextField } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AlertContext } from '../../Contexts/AlertContext';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId: string;
}

export default function GenoratePassword(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { open, onClose, userId } = props;
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [copyStatus, setCopyStatus] = React.useState(false);
    const { show } = React.useContext(AlertContext);


    React.useEffect(() => {
        if (userId !== null) {
            getData()
        }
    }, [userId]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`User/GenoratePassword?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: string) => {
                setLoading(false);
                if (data === 'Error') {
                    closeDialog();
                    show('error', 'An internal server error has occurred');
                }
                else {
                    setPassword(data);
                }
            },
            () => {
                closeDialog();
                show('error', 'An internal server error has occurred');
                setLoading(false);
            }
        );
    }

    const onCopyText = () => {
        setCopyStatus(true);
    };

    const closeDialog = () => {
        onClose(false);
        setPassword('');
        setCopyStatus(false);
    };

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{"User Password Changed"}</DialogTitle>
            <DialogContent>
                <Typography sx={{ pb: 1 }}>The User's password has been changed to a new secure generated password. Please forward this to the user via a safe mechanism.</Typography>
                <InputLabel shrink>Password</InputLabel>
                <TextField size='small' value={password} disabled={true}></TextField>
                <CopyToClipboard text={password} onCopy={onCopyText}>
                    <Button disabled={loading}>Copy to Clipboard</Button>
                </CopyToClipboard>
                {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                <Typography>You will not be able to see this password again once finishing.</Typography>
                <DialogActions>
                    <Button variant="contained" onClick={closeDialog} disabled={loading}>Finish</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}