import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloudIcon from '@mui/icons-material/CloudUpload';
import Chip from '@mui/material/Chip';
import { AlertContext } from '../Contexts/AlertContext';
import Grid from '@mui/material/Grid';

interface IProps {
    onDrop: (newFile: File | null) => void;
    onDelete: () => void;
    file: File | null;
    acceptedTypes?: string;
}

const boxStyle = [{
    display: 'flex',
    p: 2,
    bgcolor: 'grey.200',
    width: '100%',
    height: '100%',
    border: '2px dashed grey',
    borderColor: 'grey.500',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
},
{
    '&:hover': {
        bgcolor: 'grey.300',
    },
}];

export default function SingleFileUpload(props: IProps) {
    const { onDrop, onDelete, file, acceptedTypes } = props;
    const { show } = React.useContext(AlertContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.item(0)) {
            const newFile = event.target.files.item(0);

            //Limit file size to 4GB
            if (newFile!.size >= (4 * 1024 * 1024 * 1024)) {
                show('warning', "Cannot upload file size greater than 4GB.")
            } else {
                onDrop(newFile);
            }
        }
    }

    const dragEvents = {
        onDragEnter: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragLeave: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragOver: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
            if (event.dataTransfer.files.length === 1) onDrop(event.dataTransfer.files.item(0));
        }
    }

    return (
        <Grid container spacing={2}>
            {file !== null ?
                <Grid item>
                    <Chip label={file.name} onDelete={onDelete} />
                </Grid>
                :
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography >Maximum file size 4GB.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <input
                            onChange={handleChange}
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            accept={acceptedTypes}
                        />
                        <label htmlFor="file-upload" {...dragEvents} >
                            <Box sx={boxStyle}>
                                <Stack alignItems="center">
                                    <CloudIcon color="action" />
                                    <Typography>Click or Drag & Drop to Upload a File</Typography>
                                    <Typography variant="body2">You may only upload one file</Typography>
                                </Stack>
                            </Box>
                        </label>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );
}