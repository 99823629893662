import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';

interface IProps {
    open: boolean;
    onClose: () => void;
    resource: ResourceViewmodel | null;
    existingSrc?: string;
}
export default function ViewResource(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { open, onClose, resource, existingSrc } = props;
    const [fileSrc, setFileSrc] = React.useState('');

    React.useEffect(() => {
        if (resource) {
            if (!existingSrc) {
                if (resource.type !== "Embed")
                    getFileSrc();
                else
                    setFileSrc(resource.content);
            } else {
                setFileSrc(existingSrc);
            }
        }
    }, [resource]);

    const getFileSrc = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Resource/ViewResourceLink?id=${resource!.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            ((response) => {
                setFileSrc(response);
            }),
            (error => {
            })
        );
    }

    const resourceElement = React.useMemo(() => {
        if (resource) {
            switch (resource.type) {
                case "Photo":
                    return <img src={fileSrc} alt={resource.name} width='100%' height={window.innerWidth - (window.innerWidth * 0.65)} />;
                case "Video":
                    return <video controls controlsList={'nodownload'} width='100%' height={window.innerWidth - (window.innerWidth * 0.65)}> <source src={fileSrc} /></video>;
                case "Embed":
                    return <div dangerouslySetInnerHTML={{ __html: fileSrc }} />
                default:
                    return <React.Fragment />;
            }
        }
        return <React.Fragment />;
    }, [fileSrc]);

    const clearResourse = async () => {
        setFileSrc("");
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>
                {`Viewing ${resource ? resource.name : ''}`}
                <IconButton
                    aria-label="close"
                    onClick={clearResourse}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        {resourceElement}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}