import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import ResourceUserList from './ResourceUserList';
import PermissionCheck from '../Utilities/PermissionCheck';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    resources: ResourceViewmodel[];
    projectId: number;
    sectionName: string;
}

export default function EditMultipleResourceAccess(props: IProps) {
    const { open, onClose, resources, projectId, sectionName } = props;
    const resourceIds = React.useMemo(() => resources.map(item => item.id), [resources]);

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{`Manage Access for ${resources.length} new ${sectionName}`}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <PermissionCheck userType={["Admin", "Manager", "Consultant"]}>
                        <Grid item xs={12}>
                            {resources && <ResourceUserList name={`${resources.length} new ${sectionName}`} projectId={projectId} resourceIds={resourceIds} />}
                        </Grid>
                    </PermissionCheck>
                </Grid>
                <DialogActions>
                    <Button variant="contained" onClick={() => onClose(false)}>{"Close"}</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}