import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PermissionCheck from '../Utilities/PermissionCheck';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import SectionViewmodel from './Viewmodels/SectionViewmodel';
import authService from '../api-authorization/AuthorizeService';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ResourceTable from '../Resources/ResourceTable';
import UploadBlobResource from '../Resources/UploadBlobResource';
import ImageList from '@mui/material/ImageList';
import GalleryItem from '../Resources/GalleryItem';
import ViewResource from '../Resources/ViewResource';
import ViewImageCarousel from '../Resources/ViewImageCarousel';
import SectionUserList from './SectionUserList';
import EditResource from '../Resources/EditResource';
import ResourceViewmodel from '../Resources/Viewmodels/ResourceViewmodel';
import EditMultipleResourceAccess from '../Resources/EditMultipleResourceAccess';

interface IProps {
    id: number;
    projectId: number;
    archived: boolean;
    managingConsultant: string | number;
}

export default function SectionContainer(props: IProps) {
    const { id, projectId, archived, managingConsultant } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [section, setSection] = React.useState(new SectionViewmodel());
    const [loading, setLoading] = React.useState(true);
    const [collapsed, setCollapsed] = React.useState(false);
    const [tableView, setTableView] = React.useState(true);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [openUsers, setOpenUsers] = React.useState(false);
    const [selectedResource, setSelectedResource] = React.useState(-1);
    const [openEdit, setOpenEdit] = React.useState<ResourceViewmodel | null>(null);
    const [openResourceAccess, setOpenResourceAccess] = React.useState<ResourceViewmodel[] | null>(null);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        // Default photos to gallery view
        if (section.resourceType === "Photo" && !archived)
            setTableView(false);
    }, [section.resourceType]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Section/GetSection?id=${id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setSection(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const closeUploadDialog = (refresh: boolean) => {
        setOpenUpload(false);

        if (refresh)
            getData();
    }

    const collapseSection = () => {
        setCollapsed(!collapsed);
    }

    const changeView = () => {
        setTableView(!tableView);
    }

    const openViewDialog = (index: number) => {
        setOpenView(true);
        setSelectedResource(index);
    }

    const closeView = () => {
        setOpenView(false);
        setSelectedResource(-1);
    }

    const closeUsers = (refresh: boolean) => {
        setOpenUsers(false);
    }

    const closeEdit = (refresh: boolean) => {
        setOpenEdit(null);

        if (refresh)
            getData();
    }

    const closeMultipleAccessEdit = (refresh: boolean) => {
        setOpenResourceAccess(null);

        if (refresh)
            getData();
    }

    return (
        <Grid item xs={12}>
            <Paper>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid container item xs={'auto'} spacing={2}>
                        <Grid item>
                            <IconButton onClick={collapseSection} size="small">{collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }} alignItems="center">
                            <Typography variant="h1">{section.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={'auto'} spacing={2}>
                        {!archived &&
                            <PermissionCheck userType={["Admin", "Manager"]} managingConsulantName={managingConsultant}>
                                <Grid item>
                                    <Button variant="contained" fullWidth size="small" onClick={() => setOpenUsers(true)} disabled={loading}>Manage Access</Button>
                                </Grid>
                            </PermissionCheck>
                        }
                        {section.resourceType === "Photo" && !archived &&
                            <Grid item>
                                <Button variant="contained" fullWidth size="small" onClick={changeView} disabled={loading}>{tableView ? "View Gallery" : "View Table"}</Button>
                            </Grid>
                        }
                        {!archived &&
                            <PermissionCheck userType={["Admin", "Manager", "Consultant"]} > {/*Changed line */}
                                <Grid item>
                                    <Button variant="contained" fullWidth size="small" onClick={() => setOpenUpload(true)} disabled={loading}>Add {section.resourceType === "Embed" ? section.name : section.resourceType}</Button>
                                </Grid>
                            </PermissionCheck>
                        }
                    </Grid>
                </Grid>
                <Collapse in={!collapsed}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {(tableView || archived) ?
                                <ResourceTable refreshData={getData} data={section.resources} openView={openViewDialog} archived={archived} openEdit={setOpenEdit} sectionName = {section.name} />
                                :
                                <ImageList sx={{
                                    gridTemplateColumns: {
                                        xs: 'repeat(2, 1fr)!important',
                                        md: 'repeat(4, 1fr)!important'
                                    }
                                }} >
                                    {section.resources.map((item, index) =>
                                        <GalleryItem key={'galleryItem' + item.id} resource={item} openView={openViewDialog} index={index} />
                                    )}
                                </ImageList>
                            }
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>
            <ViewResource open={openView && section.resourceType !== "Photo"} resource={selectedResource > -1 ? section.resources[selectedResource] : null} onClose={closeView} />
            {openEdit && <EditResource open={openEdit !== null} resource={openEdit} projectId={projectId} onClose={closeEdit} />}
            {section.resourceType === "Photo" && section.resources.length > 0 && <ViewImageCarousel open={openView && section.resourceType === "Photo"} resources={section.resources} selectedIndex={selectedResource} onClose={closeView} sectionName={section.name} sectionId={section.id} />}
            <UploadBlobResource onClose={closeUploadDialog} open={openUpload} sectionId={section.id} sectionName={section.name} type={section.resourceType} openEditAccess={setOpenResourceAccess} />
            {openUsers && <SectionUserList name={section.name} projectId={projectId} sectionId={id} open={openUsers} onClose={closeUsers} />}
            {openResourceAccess && <EditMultipleResourceAccess open={openResourceAccess !== null} projectId={projectId} resources={openResourceAccess} onClose={closeMultipleAccessEdit} sectionName={section.name} />}
        </Grid>
    );
}