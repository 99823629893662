import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';
import EditResourceViewmodel from './Viewmodels/EditResourceViewmodel';
import ResourceUserList from './ResourceUserList';
import PermissionCheck from '../Utilities/PermissionCheck';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    resource: ResourceViewmodel;
    projectId: number;
}

export default function EditResource(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, resource, projectId } = props;
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm<EditResourceViewmodel>({
        defaultValues: {
            id: resource.id,
            name: resource.name,
            content: resource.content
        }
    });
    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            id: resource.id,
            name: resource.name,
            content: resource.content
        });
    }, [resource]);

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        // prevent nested form submit
        if (event && event.target.id === 'manageAccessForm')
            return;

        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Resource/Update', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated resource");
                    onClose(true);
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{`Edit / Manage Access to ${resource.name}`}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        {resource.type === "Embed" &&
                            <Grid item xs={12}>
                                <InputLabel htmlFor="content" shrink>Embed Code</InputLabel>
                                <TextFieldElement name="content" required fullWidth size="small" />
                            </Grid>
                        }
                        <PermissionCheck userType={["Admin", "Manager", "Consultant"]}>
                            <Grid item xs={12}>
                                <ResourceUserList name={resource.name} projectId={projectId} resourceIds={resource.id} />
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}