import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';

interface IProps {
    userId: string;
}

export default function ProfileAdminActions({ userId }: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);

    const resetPassword = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`User/ResetPassword?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', 'Error when resetting user password');
                else show('success', 'User password successfully reset');
            },
            () => {
                show('error', 'Error when resetting user password');
            }
        );
    }

    const resendEmail = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`User/ResendConfirmation?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', 'Error when resending confirmation email');
                else show('success', 'User confirmation successfully resent');
            },
            () => {
                show('error', 'Error when resending confirmation email');
            }
        );
    }

    return (
        
        <Paper sx={{ padding: 2 }}>
            <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Admin Actions</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth size="small" onClick={resendEmail}>Re-send Email Confirmation</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth size="small" onClick={resetPassword}>Reset Password</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}