import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CreateUserViewmodel from '../Viewmodels/CreateUserViewmodel';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import PermissionCheck from '../../Utilities/PermissionCheck';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateUser(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [loading, setLoading] = React.useState(false);
    const [newUser] = React.useState<CreateUserViewmodel>(new CreateUserViewmodel());
    const [title, setTitle] = React.useState<DropdownViewModel[]>([]);
    const [userRole, setRoles] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const formContext = useForm<CreateUserViewmodel>({
        defaultValues: new CreateUserViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset(newUser);
    }, [newUser]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`User/GetTitlesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setTitle(data);
            },
            () => {
                setLoading(false);
            }
        );

        const tokenRoles = await authService.getAccessToken();
        const user = await authService.getUser();
        setRoles(user.role);

        crabFetch(`User/GetRolesDropdown`, {
            headers: !tokenRoles ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${tokenRoles}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setRoles(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('User/CreateUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created User");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                onSuccess={submit}
            >
                <DialogTitle>{"Create User"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="title" shrink>Title</InputLabel>
                            <SelectElement
                                name="titleId"
                                required
                                fullWidth
                                size="small"
                                options={title}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                            <TextFieldElement name="firstName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                            <TextFieldElement name="lastName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="email" shrink>Email</InputLabel>
                            <TextFieldElement parseError={() => "Please enter a valid email address"} type={'email'} name="email" required fullWidth size="small" />
                        </Grid>
                        <PermissionCheck userType={["Admin"]}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="role" shrink>Role</InputLabel>
                                <SelectElement
                                    name="roleId"
                                    required
                                    fullWidth
                                    size="small"
                                    options={userRole}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        </PermissionCheck>
                        
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}