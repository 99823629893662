import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InstructingPartyListViewmodel from './ViewModels/InstructingPartyListViewmodel';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid/Grid';


interface IProps {
    row: InstructingPartyListViewmodel;
    editRow: (id: number) => void;
    refresh: () => void;
}

export default function InstructingPartyTableRow(props: IProps) {
    const { row, editRow, refresh } = props;

    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const date = new Date(row.created);

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.archived ? 'UnArchiveParty' : 'ArchiveParty';

        crabFetch(`InstructingParty/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} Instructing Party`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }
    return (
        <>
            <TableRow hover>
                <TableCell>{row.companyName}</TableCell>
                <TableCell>{row.contactName}</TableCell>
                <TableCell>{row.jobTitle}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.office}</TableCell>
                <TableCell>{date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric'})}</TableCell>
                <TableCell align={"right"}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button aria-label={row.archived ? "Unarchive" : "Archive" + " Instructing Party"} onClick={toggleArchive} variant="contained" disabled={loading}>{row.archived ? "Unarchive" : "Archive"}</Button>
                        </Grid>
                        <Grid item>
                            <Button aria-label={"Edit Instructing Party"} onClick={() => editRow(row.id)} variant="contained">Edit</Button>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
}