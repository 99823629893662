import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import UserViewmodel from '../Viewmodels/UserViewmodel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import PermissionCheck from '../../Utilities/PermissionCheck';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId?: string | null;

}

export default function EditUser(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, userId } = props;
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState<UserViewmodel>(new UserViewmodel());
    const [userRole, setRoles] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        if (userId !== null) {
            getData()
        }
    }, [userId]);

    const formContext = useForm<UserViewmodel>({
        defaultValues: new UserViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
        });
    }, [user]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`User/GetUser?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: UserViewmodel) => {
                setLoading(false);
                setUser(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`User/GetRolesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setRoles(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        const authUser = await authService.getUser();
        setLoading(true);        

        crabFetch('User/UpdateUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0)
                    show('error', data);
                else {
                    if(authUser.sub === user.id)
                        updateAuthUserState();

                    show('success', "Successfully updated User Infomation");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const updateAuthUserState = async () => {
        await authService.updateUser();
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Edit User"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                            <TextFieldElement name="firstName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                            <TextFieldElement name="lastName" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="email" shrink>Email</InputLabel>
                            <TextFieldElement parseError={() => "Please enter a valid email address"} type={'email'} name="email" required fullWidth size="small" />
                        </Grid>
                        <PermissionCheck userType="Admin">
                            <Grid item xs={12}>
                                <InputLabel htmlFor="role" shrink>Role</InputLabel>
                                <SelectElement
                                    name="role"
                                    required
                                    fullWidth
                                    size="small"
                                    options={userRole}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}