import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, AutocompleteElement, SwitchElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertContext } from '../Contexts/AlertContext';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { AccessType } from '../Utilities/AccessEnums';
import ManageAccessViewmodel from './Viewmodels/ManageAccessViewmodel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AccessListViewmodel from './Viewmodels/AccessListViewmodel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    id: number | number[];
    userEdit?: AccessListViewmodel | null;
    type: AccessType;
    label: string;
    projectId: number;
}

export default function ManageAccess(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, id, userEdit, type, label, projectId } = props;
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [access, setAccess] = React.useState(new ManageAccessViewmodel());
    const [expireDate, setExpireDate] = React.useState<Date>(new Date());

    const formContext = useForm<ManageAccessViewmodel>({
        defaultValues: new ManageAccessViewmodel()
    });
    const { reset } = formContext;

    React.useEffect(() => {
        getData();
        setExpireDate(new Date())
    }, []);

    React.useEffect(() => {
        if (userEdit)
            getExisting();
        else
            setAccess({
                ...access,
                accessToId: id,
                accessType: type,
                projectId: projectId
            });
    }, [id, userEdit]);

    React.useEffect(() => {
        reset(access);
    }, [access]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const resourceId = Array.isArray(id) ? id[0] : id;

        crabFetch(`Access/GetAccessUsersDropdown?type=${type}&id=${resourceId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setUsers(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getExisting = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Access/GetUserAccess?id=${userEdit!.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data) => {
                setLoading(false);
                setAccess({
                    ...access,
                    id: data.id,
                    userId: data.userId,
                    accessToId: id,
                    accessType: type,
                    accessLevel: data.accessLevel,
                    days: data.days,
                    projectId: projectId
                });

                setExpireDate(data.currentExpiry ? new Date(data.currentExpiry) : new Date())
            },
            () => {
                setLoading(false);
            }
        );
    }

    const removeAccess = async (form: any) => {

        const token = await authService.getAccessToken();
        let url = "";
        
        if (type === AccessType.Project) {
            url = "RemoveProjectAccess";
        }
        else if (type === AccessType.Section) {
            url = "RemoveSectionAccess";
        }
        else {
            url = "RemoveResourceAccess";
        }

        crabFetch(`Access/${url}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(formContext.getValues())
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully removed user access.`);
                    close(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const submit = async (form: any, event?: React.BaseSyntheticEvent<object, any, any>) => {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const token = await authService.getAccessToken();
        let url = "";

        if (userEdit)
            url = "UpdateAccess";
        else {
            if (type === AccessType.Project)
                url = "AddProjectAccess";
            else if (type === AccessType.Section)
                url = "AddSectionAccess";
            else if (Array.isArray(id)) // Adding access for multiple resources at once
                url = "AddMultipleResourceAccess";
                else
                url = "AddResourceAccess";
        }

        form.days = form.days === "" ? null : parseInt(form.days);
        form.accessLevel = form.accessLevel ? 1 : 0;

        crabFetch(`Access/${url}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${userEdit ? 'updated' : 'added'} access for user.`);
                    setAccess({
                        ...access,
                        accessToId: id,
                        accessType: type,
                        projectId: projectId
                    });
                    close(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const close = (refresh: boolean) => {
        onClose(refresh);
        setAccess({
            ...access,
            accessToId: id,
            accessType: type,
            projectId: projectId
        });
    }

    const watchDays = formContext.watch('days');

    return (
        <Dialog
            open={open}
            onClose={() => close(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
                FormProps={{ id: 'manageAccessForm' }}
            >
                <DialogTitle>{"Manage Access to " + label}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="userId" shrink>User</InputLabel>
                            {userEdit ?
                                <TextField fullWidth size="small" disabled value={userEdit && userEdit.userName} /> :
                                <AutocompleteElement
                                    name="userId"
                                    required
                                    textFieldProps={{
                                        fullWidth: true,
                                        size: "small",
                                        InputProps: {
                                            disabled: loading,
                                            startAdornment: (
                                                !loading ? null : <CircularProgress size={20} />
                                            )
                                        }
                                    }}
                                    options={users}
                                    loading={loading}
                                    matchId
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="accessLevel" shrink>Access Level</InputLabel>
                            <SwitchElement
                                label={type === AccessType.Project ? 'Project Editor' : `Editor`}
                                name={`accessLevel`}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="days" shrink>Access Expires in Days</InputLabel>
                            <TextFieldElement name="days" fullWidth size="small" type="number" validation={{ min: 0 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{(watchDays !== null && watchDays.toString().length > 0 && expireDate) && `Access Expires on ${new Date((new Date()).setDate((expireDate.getDate() + watchDays))).toLocaleDateString('en-gb')}`}</Typography>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => close(false)} color="error" disabled={loading}>Cancel</Button>
                        {userEdit &&
                            <LoadingButton onClick={removeAccess} variant="contained" color="error" loading={loading}>Remove</LoadingButton>
                        }
                        <LoadingButton variant="contained" type="submit" loading={loading}>{userEdit ? "Confirm" : "Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}