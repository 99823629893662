import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement, SwitchElement } from 'react-hook-form-mui';
import CreateProjectViewModel from '../Viewmodels/CreateProjectViewmodel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertContext } from '../../Contexts/AlertContext';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import { useForm, useFieldArray } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateProject(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [loading, setLoading] = React.useState(false);
    const [instructingParties, setInstructingParties] = React.useState<DropdownViewModel[]>([]);
    const [consultants, setConsultants] = React.useState<DropdownViewModel[]>([]);
    const [newProject, setNewProject] = React.useState<CreateProjectViewModel>(new CreateProjectViewModel());

    const formContext = useForm<CreateProjectViewModel>({
        defaultValues: new CreateProjectViewModel()
    });
    const { fields } = useFieldArray({ name: "sections", control: formContext.control });
    const { reset } = formContext;

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        reset(newProject);
    }, [newProject]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`User/GetManagingConsultantsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setConsultants(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`InstructingParty/GetInstructingPartiesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setInstructingParties(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Section/GetSectionTypes`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);

                setNewProject({
                    ...newProject,
                    sections: data.map((item) => {
                        return {
                            id: item.id as number,
                            name: item.label as string,
                            enabled: false,
                            canEdit: false
                        };
                    })
                });
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();

        crabFetch('Project/CreateProject', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created project");
                    onClose(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
            keepMounted={false}
        >
            <FormContainer
                onSuccess={submit}
                defaultValues={{
                    name: '',
                    instructingParty: 0,
                    managingConsultant: '',
                    sections: undefined
                }}
                formContext={formContext}
            >
                <DialogTitle>{"Create Project"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="instructingParty" shrink>Instructing Party</InputLabel>
                            <SelectElement
                                name="instructingParty"
                                required
                                fullWidth
                                size="small"
                                options={instructingParties}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                                
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="managingConsultant" shrink>Managing Consultant</InputLabel>
                            <SelectElement
                                name="managingConsultant"
                                required
                                fullWidth
                                size="small"
                                options={consultants}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} pt={1 }>
                            <InputLabel htmlFor="sections" shrink>Select Sections</InputLabel>
                            {fields.map((field, index) => {
                                return (
                                    <React.Fragment key={field.id}>
                                        <SwitchElement
                                            label={field.name}
                                            name={`sections.${index}.enabled`}
                                            sx={{ width: '100%' }}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}