import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { FormContainer, PasswordElement, PasswordRepeatElement } from 'react-hook-form-mui';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';

interface IProps {
    userId: string;
    onClose?: (refresh: boolean) => void;
}

export default function ChangePassword({ userId, onClose }: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [open, setOpen] = React.useState(false);
    const [profileUserId, setProfileUserId] = React.useState('');

    React.useEffect(() => {
        checkLoad();
    }, [])

    const checkLoad = async () => {
        const user = await authService.getUser();
        setProfileUserId(user.sub);
    }

    const toggleOpen = () => setOpen((prev) => !prev);

    const internalOnClose = () => {
        setOpen(false);
    }

    const changePassword = async (form: any) => {
        const token = await authService.getAccessToken();

        crabFetch(`User/UpdatePassword`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', 'Error when changing user password');
                else show('success', 'User password successfully changed');
                setOpen(false);
            },
            () => {
                show('error', 'Error when changing user password');
            }
        );
    }

    return (
        profileUserId === userId ?
            <React.Fragment>
                <Button size="small" fullWidth variant="contained" onClick={toggleOpen}>Change Password</Button>
                <Dialog
                    open={open}
                    onClose={onClose ? onClose : internalOnClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <FormContainer
                        defaultValues={{
                            oldPassword: '', newPassword: '', confirmPassword: ''
                        }}
                        onSuccess={changePassword}
                    >
                        <DialogTitle>Change Password</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="oldPassword" shrink>Old Password</InputLabel>
                                    <PasswordElement name="oldPassword" fullWidth size="small"
                                        validation={{
                                            pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="newPassword" shrink>New Password</InputLabel>
                                    <PasswordElement name="newPassword" fullWidth size="small"
                                        validation={{
                                            pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="confirmPassword" shrink>Confirm Password</InputLabel>
                                    <PasswordRepeatElement name="confirmPassword" fullWidth size="small" passwordFieldName="newPassword" />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Button size="small" color="error" onClick={onClose ? () => onClose(false) : internalOnClose}>Cancel</Button>
                                <Button size="small" variant="contained" color="primary" type="submit">Confirm</Button>
                            </DialogActions>
                        </DialogContent>
                    </FormContainer>
                </Dialog>
            </React.Fragment>
            :
            <React.Fragment />
    );
}