import * as React from 'react';
import Grid from '@mui/material/Grid';
import UserManagementTable from './UserManagementTable';

export default function UserDashboard() {
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <UserManagementTable />
            </Grid>
        </Grid>
    );
}