import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import EditUser from '../Users/User/EditUser';
import ProfileViewModel from './Viewmodels/ProfileViewModel';
import ChangePassword from './ChangePassword';

interface IProps {
    userId: string;
}

export default function ProfileDetails(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [user, setUser] = React.useState(new ProfileViewModel());
    const [loading, setLoading] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch(`Profile/GetProfile?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setUser(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const closeDialog = async (refresh: boolean) => {
        if (refresh) getData();
        setOpenDialog(false);
    }

    const toggleEdit = () => setOpenDialog((prev) => !prev);

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems={loading ? "flex-end" : "center"}>
                        <Grid item>
                            <Typography variant="h5">Profile Details</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={!loading}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1"><b>Forename:</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{user.firstName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1"><b>Surname:</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{user.lastName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1"><b>Email:</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{user.email}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1"><b>Role:</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{user.role}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ChangePassword userId={userId} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button size="small" fullWidth variant="contained" onClick={toggleEdit}>Edit Details</Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
            <EditUser open={openDialog} onClose={closeDialog} userId={userId} />
        </Paper>
    );
}