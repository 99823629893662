import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

interface IProps {
    text: string;
    buttonText: string;
    open: boolean;
    okLink?: any;
    okClick?: () => void;
    close: () => void;
    disabled?: boolean;
}

export default function WarningDialog(props: IProps) {
    const { open, close, okLink, okClick, text, buttonText, disabled } = props;

    const cancel = () => {
        close();
    }

    const clickedOk = () => {
        if (okClick) {
            okClick();
        }
    }

    const isDisabled = disabled === undefined ? false : disabled;

    const okButton = okLink ?
        <Button variant="contained" color="primary" component={okLink} disabled={isDisabled}>{buttonText}</Button> :
        <Button variant="contained" color="primary" onClick={clickedOk} disabled={isDisabled}>{buttonText}</Button>;

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && okClick) {
            okClick();
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="warning dialog"
            onKeyDown={handleEnterKeyPress}
        >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
                <DialogActions>
                    <Button onClick={cancel} variant="outlined" color="error" disabled={disabled}>Cancel</Button>
                    {okButton}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}