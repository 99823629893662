import * as React from "react";
import authService from '../api-authorization/AuthorizeService';

interface IProps {
    permission?: string | string[];
    userType?: string | string[];
    children?: React.ReactNode;
    managingConsulantName?: string | number;
}

export const CheckHasPermission = async (role: string | string[]) => {
    const user = await authService.getUser();

    if ((user && user.role && user.role === "Admin")) {
        return true;
    }
    else if (user && user.role && Array.isArray(role) && role.includes(user.role)) {
        return true;
    }
    else if (user && user.role && user.role === role) {
        return true;
    }
    
    

    return false;
}

export default function PermissionCheck(props: IProps) {
    const { permission,userType, children, managingConsulantName} = props;
    const [hasAccess, setHasAccess] = React.useState(false);

    React.useEffect(() => {
        if (permission && permission.length > 0) {
            checkPermission();
        }
    }, [permission]);

    React.useEffect(() => {
        if (userType && userType.length > 0) {
            checkUserType();
        }
    }, [userType]);

    const checkPermission = async () => {
        const user = await authService.getUser();
        

        if ((user && user.role && user.role === "Admin")) {
            setHasAccess(true);
        }
        else if (user && user.permission) {
            if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
                setHasAccess(true);
            } else if (user.permission.includes(permission)) {
                setHasAccess(true);
            } else {
                setHasAccess(false);
            }
        } else {
            setHasAccess(false);
        }
    }


    const checkUserType = async () => {
        const user = await authService.getUser();

        if ((user && user.role && user.role === "Admin")) {
            setHasAccess(true);
        }
        else if (user && user.role && Array.isArray(userType) && userType.includes(user.role))
        { 
            setHasAccess(true);
        }
        else if (user && user.role && user.role === userType) {
            setHasAccess(true);
        }
        else if (user && managingConsulantName && user.name === managingConsulantName)
        {
            setHasAccess(true);
        } 
        else {
            setHasAccess(false);
        }
    }

    return (
        <React.Fragment>
            {(hasAccess) && children}
        </React.Fragment>
    );
}