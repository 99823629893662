import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, DatePickerElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';

interface IProps {
    projectId: number;
    currentStatus: DropdownViewModel;
    open: boolean;
    onClose: (refresh: boolean) => void;
    statusDueDate: Date;
}

export default function UpdateStatus(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, currentStatus, projectId } = props;
    const [loading, setLoading] = React.useState(false);
    const [statuses, setStatuses] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Status/GetStatusesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);

                if (data.findIndex(f => f.id === currentStatus.id) === -1) {
                    data.push(currentStatus);
                }

                setStatuses(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        if (form.dueDate) {
            const offset = form.dueDate.utcOffset();
            form.dueDate = form.dueDate.add(offset, 'm');
        }

        crabFetch(`Project/UpdateStatus?id=${projectId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated status");
                    onClose(true);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth

        >
            <FormContainer
                defaultValues={{
                    status: currentStatus.id,
                    dueDate: null
                }}
                onSuccess={submit}
            >
                <DialogTitle>{"Update Status"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="status" shrink>Status</InputLabel>
                            <SelectElement
                                name="status"
                                required
                                fullWidth
                                size="small"
                                options={statuses}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="enGB" >
                                <DatePickerElement name="dueDate" inputProps={{ fullWidth: true }} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}