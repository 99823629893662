import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadResourceViewmodel from './Viewmodels/UploadResourceViewmodel';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import SingleFileUpload from '../Utilities/SingleFileUpload';
import FileUpload from '../Utilities/FileUpload';
import PermissionCheck, { CheckHasPermission } from '../Utilities/PermissionCheck';
import ResourceViewmodel from './Viewmodels/ResourceViewmodel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    sectionId: number;
    sectionName: string;
    type: string;
    openEditAccess: (resource: ResourceViewmodel[]) => void;
}

export default function UploadBlobResource(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, sectionId, sectionName, type, openEditAccess } = props;
    const [loading, setLoading] = React.useState(false);
    const [files, setFiles] = React.useState<File[]>([]);

    const formContext = useForm<UploadResourceViewmodel>({
        defaultValues: {
            sectionId: sectionId,
            type: type,
            name: null,
            resources: null,
            content: null
        }
    });
    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            sectionId: sectionId,
            type: type,
            name: null,
            resources: null,
            content: null
        });
    }, [sectionId]);

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault();
        const submitEvent = event?.nativeEvent as SubmitEvent;

        const uploadOnly = submitEvent && submitEvent.submitter?.id === 'upload';

        const token = await authService.getAccessToken();
        const canEditUsers = await CheckHasPermission(["Admin", "Manager", "Consultant"]);
        setLoading(true);

        if (files.length > 0)
            form.resources = files;

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);

        console.log();
        crabFetch('Resource/Upload', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }, ResponseType.Response,
            (response: Response) => {
                if (response.ok) {
                    show('success', "Successfully uploaded resource.");
                    cancel(true);

                    if (canEditUsers && !uploadOnly) {
                        response.json()
                            .then((data: ResourceViewmodel[]) => {
                                openEditAccess(data);
                            });
                    }
                }
                else {
                    response.text()
                        .then((data: string) => {
                            show('error', data);
                    });
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...files];
        existingFiles = existingFiles.concat(newFiles);
        setFiles(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...files];
        existingFiles.splice(index, 1);
        setFiles(existingFiles);
    }

    const cancel = (refresh: boolean) => {
        reset({
            sectionId: sectionId,
            type: type,
            name: null,
            resources: null,
            content: null
        });
        setFiles([]);
        onClose(refresh);
    }

    const handleClose = (event: any, reason: string) => {
        if (reason && reason === "backdropClick")
            return;
        cancel(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{`Add new ${sectionName}`}</DialogTitle>
                <DialogContent>
                    {type === "Embed" ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="name" shrink>Name</InputLabel>
                                <TextFieldElement name="name" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="content" shrink>Embed Code</InputLabel>
                                <TextFieldElement multiline rows={3} name="content" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {type === "Video" ?
                                    <SingleFileUpload onDrop={(newFile) => { if (newFile) onFileDrop([newFile]) }} onDelete={() => onFileDelete(0)} file={files.length > 0 ? files[0] : null} acceptedTypes="video/*" />
                                    :
                                    <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={files} acceptedTypes={type === "Photo" ? "image/*" : undefined}/>
                                }
                            </Grid>
                        </Grid>
                    }
                    <DialogActions>
                        <Button onClick={() => cancel(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading} id="upload">{"Upload"}</LoadingButton>
                        <PermissionCheck userType={["Admin", "Manager", "Consultant"]}>
                            <LoadingButton variant="contained" type="submit" loading={loading} id="manageAccess">{"Upload & Manage Access"}</LoadingButton>
                        </PermissionCheck>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}