import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement} from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateStatus(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [loading, setLoading] = React.useState(false);

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Status/CreateStatus?status=' + form.status, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created status");
                    onClose(true);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                defaultValues={{
                    status: ''
                }}
                onSuccess={submit}
            >
                <DialogTitle>{"Create Status"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="status" shrink>Status</InputLabel>
                            <TextFieldElement name="status" required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" loading={loading} loadingPosition="start">{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}