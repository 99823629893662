import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function ConfirmGeneration(props: IProps) {
    const { open, onClose } = props;
    
  
    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>This will reset the users's password</DialogTitle>
            <DialogContent>
                <Typography sx={{ pb: 1 }}>A new secure password will be generated for the user and changed immediately. Are you sure you wish to continue?</Typography>
                <DialogActions>
                    <Button variant="contained" onClick={() => onClose(true)}>Yes</Button>
                    <Button onClick={() => onClose(false)} color="error">Cancel</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}